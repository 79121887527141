import './Users.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import UserProfile from '../../../commonComponents/UserProfile/UserProfile';
import UsersListStateful from '../../../commonComponents/UsersList/UsersListStateful';
import * as api from '../../../utilities/ServiceManager';
import Activity from './Activity/Activity';
import NewStudent from './NewStudent';
import ProfileSheet from './ProfileSheet/ProfileSheet';
import Statistics from './Statistics/Statistics';

class Users extends React.Component {
  static propTypes = {
    users: PropTypes.array,
    pagination: PropTypes.object.isRequired,
    getUsersFunction: PropTypes.func.isRequired,
  };

  state = {
    users: [],
    newStudent: false,
    search: '',
    filterBy: '',
    reloadCycle: false,
  };

  componentWillUnmount() {
    this.props.cleanUsers();
  }

  componentWillMount() {
    // Don't load new users if they have been loaded already
    if (this.props.users.length === 0 || this.state.search === '') {
      this.getUsers();
    }
  }

  componentWillReceiveProps({ users = [], selectedUser }) {
    const updateState = { loading: false };
    if (
      this.props.selectedUser &&
      this.props.selectedUser.id &&
      !this.state.fetchedUser
    ) {
      this.props.loadSelectedUser(this.props.selectedUser.id);
      this.setState({ fetchedUser: true });
    }

    if (users.length && !this.props.selectedUser.id) {
      const profile = users.find(
        (user) => user.id === this.props.selectedUser.id,
      );
      this.props.setSelectedUser(profile || users[0]);
    }
    const queryCycles = `rank=${selectedUser.rank}&dutyState=${selectedUser.dutyState}&userId=${selectedUser.id}`;
    if (
      selectedUser &&
      selectedUser.id &&
      selectedUser.id !== this.props.selectedUser.id
    ) {
      this.props.loadUserSubscription(selectedUser);
      this.getUserCycles(queryCycles);
      this.props.loadPracticeExams(selectedUser.id);
      this.props.getUserNote(selectedUser.id);
      this.props.getCouponUserAction(selectedUser.id);
      this.props.getCylesInformation(selectedUser.id);
      this.props.getCancellationReason(selectedUser.id);
      this.props.getCancellationNoteAction(selectedUser.id);
      this.props.loadApplicableCoupons(selectedUser.id);
      this.props.getUserVerification(selectedUser.id);
      this.props.getUserEducationalInstitutionData(selectedUser.id);
      this.props.getBetaUser(selectedUser.id);
    }
    if (this.state.reloadCycle) {
      this.getUserCycles(queryCycles);
      this.setState({ reloadCycle: false });
    }
    this.setState(updateState);
  }

  getUserStatus = async (info) => {
    const queryStatus = `id=${info.userId}&isStudent=${info.isStudent}`;
    try {
      await api.getUserStatus(queryStatus).then((data) => {
        if (data.data != null) {
          const time = new Date();
          const activity = data.data.path;
          const when = data.data.time;
          const diff = time - when;
          const isNowActive = diff < 120000;
          const mobile = data.data.isMobile;
          this.setState({
            path: activity,
            isNowActive,
            mobile,
          });
        } else {
          this.setState({ isNowActive: false });
        }
      });
    } catch (error) {}
  };

  getUserCycles = async (queryCycles) => {
    try {
      await api.findCyclesByUser(queryCycles).then((data) => {
        this.setState({ cyclesList: data.data });
      });
    } catch (err) {}
  };

  updateCycle = () => {
    this.setState({ reloadCycle: true });
  };

  loadCycles = (query) => {
    if (query.indexOf('undefined') === -1) {
      this.getUserCycles(query);
    }
  };

  getUsers = (search, page = 0, filterBy = '') => {
    this.setState({ loading: true });
    this.props.getUsersFunction(search, page, filterBy);
  };

  setSearch = (search) => {
    this.setState({ loading: true, search });
    this.debounce(() => this.getUsers(search, 0, this.state.filterBy), 500);
  };

  setFilterBy = (filterBy) => {
    this.setState({ loading: true, filterBy });
    this.props.cleanUsers();
    this.debounce(() => this.getUsers(this.state.search, 0, filterBy), 500);
  };

  debounce = (func, wait = 500, immediate = false, ...theArgs) => {
    const context = this;
    const args = theArgs;
    const later = () => {
      this.timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !this.timeout;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };

  getUserStatistics = (user, page = 1) => {
    this.props.loadUserStatistics(user, page);
  };

  toggleNew = () => {
    this.props.setIsNewStudent(!this.props.isNewStudent);
  };

  clearSearchInput = () => {
    this.setState({ search: '' }, () => {
      this.usersListStateful.props.reloadUsers(this.state.search);
      this.props.selectedUser.id = null;
    });
  };

  render() {
    return (
      <div className="usersSectionContainer">
        <Row className="containerRow">
          <Col
            xs={3}
            md={3}
            lg={3}
            style={{
              paddingRight: '0px',
              paddingLeft: '0px',
              maxWidth: '480px',
              minWidth: '320px',
            }}
            className="usersListSector"
          >
            <div className="usersListContainer">
              <UsersListStateful
                ref={(ref) => (this.usersListStateful = ref)}
                search={this.state.search}
                filterBy={this.state.filterBy}
                loading={this.state.loading}
                users={this.props.users}
                profile={this.props.selectedUser}
                reloadUsers={this.getUsers}
                pagination={this.props.pagination}
                toggleNew={this.toggleNew}
                clearSearchInput={this.clearSearchInput}
                newStudent={this.props.isNewStudent}
                setSelectedUser={this.props.setSelectedUser}
                loadUserStatistics={this.getUserStatistics}
                generalPermissions={this.props.generalPermissions}
                loggedUser={this.props.user}
                onSetSearch={this.setSearch}
                onSetFilterBy={this.setFilterBy}
              />
            </div>
          </Col>
          <Col
            xs={this.props.isNewStudent ? 6 : 3}
            md={this.props.isNewStudent ? 6 : 3}
            style={{
              paddingRight: '0px',
              paddingLeft: '0px',
              maxWidth: '480px',
              minWidth: '320px',
              padding: '15px',
            }}
          >
            <div className="userProfileContainer">
              {this.props.isNewStudent ? (
                <NewStudent
                  ref={(ref) => (this.newStudent = ref)}
                  toggleNew={this.toggleNew}
                  clearSearchInput={this.clearSearchInput}
                  rates={this.props.rates}
                  loadRates={this.props.loadRates}
                  handleSubmit={this.props.handleSubmit}
                  createStudent={this.props.createStudent}
                  loading={this.props.loading}
                  plans={this.props.plans}
                  loadPlans={this.props.loadPlans}
                  creationResponse={this.props.creationResponse}
                  resetForm={this.props.resetForm}
                  message={this.props.message}
                  setMessage={this.props.setMessage}
                  getUsers={this.getUsers}
                  loadUserStatistics={this.props.loadUserStatistics}
                  setSelectedUser={this.props.setSelectedUser}
                  onSetSearch={this.setSearch}
                  setNewStudentError={this.props.setNewStudentError}
                  newStudentError={this.props.newStudentError}
                />
              ) : (
                <UserProfile
                  onShowNotification={this.props.onShowNotification}
                  profile={this.props.selectedUser}
                  onDelete={this.props.deleteUser}
                  onResetUserQuestions={this.props.onResetUserQuestions}
                  onResetPassword={this.props.resetPassword}
                  onToggleActive={this.props.toggleActive}
                  onUnlockUser={this.props.unlockUser}
                  updateUser={this.props.updateUser}
                  rates={this.props.rates}
                  loadRates={this.props.loadRates}
                  generalPermissions={this.props.generalPermissions}
                  loggedUser={this.props.user}
                  loginAs={this.props.loginAs}
                  userSubscription={this.props.userSubscription}
                  updateUserSubscription={this.props.updateUserSubscription}
                  forcePasswordUpdate={() =>
                    this.props.forcePasswordUpdate(this.props.selectedUser)
                  }
                  cycles={this.state.cyclesList}
                  updateCycle={this.updateCycle}
                  loadCycles={this.loadCycles}
                  loadPracticeExams={this.props.loadPracticeExams}
                  practiceExams={this.props.practiceExams}
                  practiceExamsLoading={this.props.practiceExamsLoading}
                  restartPracticeExam={this.props.restartPracticeExam}
                  restartPracticeExamWaiting={
                    this.props.restartPracticeExamWaiting
                  }
                  setRestartPracticeExamSuccess={
                    this.props.setRestartPracticeExamSuccess
                  }
                  restartPracticeExamSuccess={
                    this.props.restartPracticeExamSuccess
                  }
                  applicableCoupons={this.props.applicableCoupons}
                  userVerification={this.props.userVerification}
                  getUserVerification={this.props.getUserVerification}
                  setUserVerification={this.props.setUserVerification}
                  couponUser={this.props.couponUser}
                  blockUser={this.props.blockUser}
                  userAdmin={this.props.user}
                  showChangeExamTypeModal={this.props.showChangeExamTypeModal}
                  setShowChangeExamTypeModal={
                    this.props.setShowChangeExamTypeModal
                  }
                  countByDate={
                    this.props.statistics.data ? this.props.statistics.data : []
                  }
                />
              )}
            </div>
          </Col>
          {!this.props.isNewStudent && (
            <Col
              xs={3}
              md={3}
              lg={3}
              style={{
                paddingRight: '0px',
                paddingLeft: '0px',
                maxWidth: '480px',
                minWidth: '320px',
                padding: '15px',
              }}
            >
              <Statistics
                user={this.props.selectedUser}
                statistics={this.props.statistics}
                isNowActive={this.state.isNowActive}
                mobile={this.state.mobile}
                path={this.state.path}
                noteDoNotContact={this.props.noteDoNotContact}
                setDoNotContact={this.props.setDoNotContact}
                getUserNote={this.props.getUserNote}
                setIntercomCancellation={this.props.setIntercomCancellation}
                getCancellationReason={this.props.getCancellationReason}
                reasonCancellation={this.props.reasonCancellation}
                upadteCancellationReason={this.props.upadteCancellationReason}
                intercomCancellationReason={
                  this.props.intercomCancellationReason
                }
                updateEducationalInstitutionData={
                  this.props.updateEducationalInstitutionData
                }
                getUserEducationalInstitutionData={
                  this.props.getUserEducationalInstitutionData
                }
                educationalInstitutionData={
                  this.props.educationalInstitutionData
                }
                setUserSubmissionBlockStatus={
                  this.props.setUserSubmissionBlockStatus
                }
                isLoadingBlockStatus={this.props.isLoadingBlockStatus}
                setUserFavoriteBlockStatus={
                  this.props.setUserFavoriteBlockStatus
                }
                isLoadingFavoriteBlockStatus={
                  this.props.isLoadingFavoriteBlockStatus
                }
                setCheckBetaUser={this.props.setCheckBetaUser}
                isBetaUser={this.props.isBetaUser}
              />
            </Col>
          )}
          {!this.props.isNewStudent && (
            <Col
              xs={3}
              md={3}
              lg={3}
              style={{
                paddingRight: '0px',
                paddingLeft: '0px',
                maxWidth: '480px',
                minWidth: '320px',
                padding: '15px 30px 15px 15px',
              }}
            >
              <Activity
                user={this.props.selectedUser}
                countByDate={
                  this.props.statistics.data ? this.props.statistics.data : []
                }
                loading={this.props.loadingInfo}
                pagination={this.props.activityPagination}
                loadUserStatistics={this.props.loadUserStatistics}
                filtered={
                  this.props.statistics.filterUsed
                    ? this.props.statistics.filterUsed
                    : 'All Activity'
                }
              />

              {this.props.listNewCycles &&
              this.props.listNewCycles.length > 0 ? (
                <div>
                  <ProfileSheet
                    user={this.props.selectedUser}
                    listNewCycles={
                      this.props.listNewCycles &&
                      Object.keys(this.props.listNewCycles).length !== 0
                        ? this.props.listNewCycles
                        : []
                    }
                    setDisableCyleAction={this.props.setDisableCyleAction}
                    getCylesInformation={this.props.getCylesInformation}
                    disableCycle={this.props.disableCycle}
                  />
                </div>
              ) : (
                <div className="notCycles">
                  <span>Profile sheet information not available</span>
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default Users;
