import { useSelector } from 'react-redux';

import {
  generateBooksMissingAnswersReport,
  getLatestBooksMissingAnswersReport,
} from '../../actions';
import {
  getBooksMissingAnswersReportData,
  getBooksMissingAnswersReportState,
} from '../../selectors';
import ReportWithoutCriteria from '../ReportWithoutCriteria';
import {
  BOOKS_MISSING_ANSWERS_REPORT_ERROR,
  BOOKS_MISSING_ANSWERS_REPORT_LOADING,
  BOOKS_MISSING_ANSWERS_REPORT_SUCCESS,
} from './reportStates';

export default function BooksMissingAnswersReport() {
  const reportState = useSelector(getBooksMissingAnswersReportState);
  const reportData = useSelector(getBooksMissingAnswersReportData);

  return (
    <ReportWithoutCriteria
      name="Books Missing Answers Report"
      link={reportData.link}
      currentState={reportState}
      stateLoading={BOOKS_MISSING_ANSWERS_REPORT_LOADING}
      stateError={BOOKS_MISSING_ANSWERS_REPORT_ERROR}
      stateSuccess={BOOKS_MISSING_ANSWERS_REPORT_SUCCESS}
      getLatestFunc={getLatestBooksMissingAnswersReport}
      generateFunc={generateBooksMissingAnswersReport}
    />
  );
}
