import './FavoriteQuestionsReport.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { SimpleInput } from '../../../../commonComponents/Input/Input';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import RatingExamSelector from '../RatingExamSelector/RatingExamSelector';

export default class FavoriteQuestionsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: [],
      selectedRatingExam: [],
      rateSearch: '',
    };
  }

  componentDidMount() {
    if (!this.props.rates && !this.ratesIsLoading) {
      this.props.loadRates();
    } else {
      this.setState({
        rates: this.props.rates.slice(1).map((rate) => ({
          id: rate.id,
          name: rate.name,
          description: rate.description,
          shortname: rate.shortname,
          isSelected: false,
        })),
      });
    }
    this.props.getLastFavoriteQuestionsReports();
  }

  areAllRatesSelected = () => {
    let areAllRatesSelected = true;
    let areAllRatesDeselected = true;
    this.state.rates.forEach((currentRate) => {
      if (currentRate.isSelected) {
        areAllRatesDeselected = false;
      } else {
        areAllRatesSelected = false;
      }
    });
    return {
      areAllRatesSelected,
      areAllRatesDeselected,
    };
  };

  onChangeRateSelection = (rateId, newValue) => {
    const { rates } = this.state;
    const checkedRate = rates.find((r) => r.id === rateId);
    checkedRate.isSelected = newValue;
    if (!checkedRate.isSelected) {
      this.deselectRelatedRE(checkedRate.shortname);
    }
    this.setState({ rates });
  };

  changeAllValues = (newValue) => {
    const { rates } = this.state;

    if (!newValue) {
      this.setState({ selectedRatingExam: [] });
    }

    this.setState({
      rates: rates.map((rate) => {
        rate.isSelected = newValue;
        return rate;
      }),
    });
  };

  deselectRelatedRE = (ratingExam) => {
    const { selectedRatingExam } = this.state;
    const result = [];
    selectedRatingExam.forEach((SRE) => {
      if (SRE.slice(7) !== ratingExam) {
        result.push(SRE);
      }
    });

    this.setState({ selectedRatingExam: result });
  };

  renderSelectAllOrNothing = (areAllRatesDeselected) => (
    <Row>
      <Col md={12} className="all-or-none">
        <SimpleInput
          type="checkbox"
          description={areAllRatesDeselected ? 'Select All' : 'Deselect All'}
          value={!areAllRatesDeselected}
          onChange={() => {
            this.changeAllValues(!!areAllRatesDeselected);
          }}
        />
      </Col>
    </Row>
  );

  renderRateAndCheckbox = (rate) => {
    if (rate.id) {
      let textLink = 'no data';
      let report = {};
      if (Array.isArray(this.props.lastFavoriteQuestionsReports)) {
        report = this.props.lastFavoriteQuestionsReports.find(
          (r) => r.rate === rate.id,
        );
        textLink = `${rate.shortname} - ${new Date(report?.updatedAt).toLocaleString()}`;
      }
      const isLoading = report && report.isLoading;

      return (
        <Row key={rate.id}>
          <Col md={12} className="rateRow">
            {isLoading && <Spinner />}
            {!isLoading && report && report.reportLink && (
              <a href={report.reportLink}>
                <span>{textLink}</span>
              </a>
            )}
            <SimpleInput
              description={`(${rate.shortname}) ${rate.name}`}
              type="checkbox"
              value={rate.isSelected}
              onChange={() => {
                this.onChangeRateSelection(rate.id, !rate.isSelected);
              }}
              disabled={isLoading}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  generateReportForSelectedRates = () => {
    const selectedRatesIds = this.state.rates
      .filter((rate) => rate.isSelected)
      .map((rate) => rate.id);
    this.changeAllValues(false);
    this.props.generateFavoriteQuestionsReport(
      selectedRatesIds,
      this.state.selectedRatingExam,
    );
  };

  changeRatingExamSelected = (ratingExamSelected) => {
    const { selectedRatingExam } = this.state;
    const index = selectedRatingExam.indexOf(ratingExamSelected);
    if (index !== -1) {
      selectedRatingExam.splice(index, 1);
    } else {
      selectedRatingExam.push(ratingExamSelected);
    }
    this.setState({ selectedRatingExam });
  };

  selectAllRatingExam = (ratingExams) => {
    const { selectedRatingExam } = this.state;

    ratingExams.forEach((RE) => {
      if (!selectedRatingExam.includes(RE)) {
        selectedRatingExam.push(RE);
      }
    });
    this.setState({ selectedRatingExam });
  };

  render() {
    const { areAllRatesDeselected } = this.areAllRatesSelected();
    return (
      <Row>
        {this.props.ratesIsLoading ||
        this.props.loadingFavoriteQuestionsReport ? (
          <Spinner />
        ) : (
          <div className="d-flex w-100">
            <Col>
              <div
                className={
                  !areAllRatesDeselected
                    ? 'generate-report'
                    : 'generate-report disabled'
                }
                onClick={() => {
                  if (!areAllRatesDeselected) {
                    this.generateReportForSelectedRates();
                  }
                }}
              >
                Generate Report
              </div>
              <div className="report-title">
                Select the rates you want included in the Favorite Questions
                Report
              </div>
              <span>Search Rate</span>
              <SimpleInput
                value={this.state.rateSearch}
                onChange={(e) =>
                  this.setState({
                    rateSearch: e.target.value,
                  })
                }
              />
              {this.renderSelectAllOrNothing(areAllRatesDeselected)}
              <Row>
                <Col>
                  {this.state.rates
                    .filter((r) =>
                      this.state.rateSearch !== ''
                        ? `(${r.shortname}) ${r.name}`
                            .toLowerCase()
                            .includes(this.state.rateSearch.toLowerCase())
                        : true,
                    )
                    .map((rate, index) =>
                      this.renderRateAndCheckbox(rate, index),
                    )}
                </Col>
                <div className="sticky-rating-selector">
                  {!areAllRatesDeselected && (
                    <RatingExamSelector
                      selectedRatingExam={this.state.selectedRatingExam}
                      selectAllRatingExam={this.selectAllRatingExam}
                      changeRatingExamSelected={this.changeRatingExamSelected}
                    />
                  )}
                </div>
              </Row>
            </Col>
          </div>
        )}
      </Row>
    );
  }
}

FavoriteQuestionsReport.propTypes = {
  rates: PropTypes.array.isRequired,
  ratesIsLoading: PropTypes.bool.isRequired,
  loadRates: PropTypes.func.isRequired,
  generateReportForSelectedRates: PropTypes.func,
  favoriteQuestionsReportIsLoading: PropTypes.bool,
  favoriteQuestionsReportError: PropTypes.string,
};
