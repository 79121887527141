import './UserProfile.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import Select from "../Select/Select";
import Select from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { InlineInput, InlineTextArea } from '../Input/Input';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export default class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      value: '',
    };
  }

  componentDidUpdate() {
    if (this.state.editMode) {
      this.openSelect();
    }
  }

  componentWillReceiveProps({ value }) {
    if (this.props.value === value) return;
    if (value && value.id) {
      this.setState({ value: value.id });
    } else {
      this.setState({ value });
    }
  }

  openSelect = () => {
    if (this.props.select && this.refs && this.refs.select) {
      this.refs.select.focus();
    }
  };

  validateEmail(e) {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRex.test(e)) {
      this.setState({ error: false });
      return true; // 'has-success'
    }
    this.setState({ error: true });

    return false; // 'has-danger'
  }

  validatePassword = (value) => {
    let criteria = 0;
    let valid = false;

    if (/[a-z]/.test(value)) {
      criteria++;
    }
    if (/[A-Z]/.test(value)) {
      criteria++;
    }
    if (/^[a-zA-Z0-9]*$/.test(value) === false) {
      criteria++;
    }
    if (/\d/.test(value) === true) {
      criteria++;
    }
    if (criteria >= 3 && value.length >= 8) {
      valid = true;
    }
    this.setState({ error: !valid });
    return valid;
  };

  onSubmit = () => {
    if (!this.state.value && !this.props.select) {
      this.setState({ error: true });
      return;
    }
    const updatedUser = { ...this.props.profile };
    const updateUserVerification = { ...this.props.userVerification };

    updatedUser[this.props.nameForValue] = this.state.value;
    if (!this.props.isPassword) delete updatedUser.password;
    if (!this.props.isRate) delete updatedUser.rate;
    for (const propName in updatedUser) {
      if (
        updatedUser[propName] === null ||
        updatedUser[propName] === undefined
      ) {
        delete updatedUser[propName];
      }
    }

    if (this.props.isRate) {
      const newRate = this.props.items.filter((r) => r.id === updatedUser.rate);
      if (newRate[0]?.isPmkOnly && updatedUser.dutyState !== 'Reserve') {
        updatedUser.dutyState = 'Reserve';
      }
      updatedUser.rate = this.state.value.id;
    }

    switch (this.props.valueName) {
      case 'Exam paygrade':
        updatedUser.rank = this.state.value.id;
        break;

      case 'Exam type':
        updatedUser.dutyState = this.state.value.name;
        if (updatedUser.dutyState === 'Substitute') {
          updatedUser.mustChangeCycle = true;
        }
        break;

      case 'Cycle':
        updatedUser.cycleSelected = this.state.value.id;
        break;

      default:
        break;
    }

    if (this.props.userVerification && this.props.nameForValue) {
      updateUserVerification[this.props.nameForValue] =
        this.props.nameForValue === 'tShirtSize'
          ? this.state.value.name
          : this.state.value;
      this.props.updateUser(updateUserVerification, this.props.isRate);
      this.setState({
        error: false,
        editMode: false,
      });
    } else if (updatedUser) {
      if (this.props.iOStoStripe) {
        updatedUser.iOStoStripe = true;
        updatedUser.stripeId = this.props.stripeId;
        this.props.setIOStoStripe();
      } else {
        updatedUser.iOStoStripe = false;
      }
      this.props.updateUser(updatedUser, this.props.isRate);
      this.setState({
        error: false,
        editMode: false,
      });
    }
  };

  render() {
    let value = this.state.value;
    if (value && value.id) {
      value = value.id;
    }

    return (
      <Row className="cardRowContainer" style={{ width: '100%' }}>
        <Col xs={12} md={12} lg={4} style={{ paddingRight: '0px' }}>
          <div className="valueName">{this.props.valueName}</div>
        </Col>
        <Col
          xs={this.state.editMode ? 12 : 10}
          md={this.state.editMode ? 12 : 10}
          lg={this.state.editMode ? 8 : 6}
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
          className="middleColumn"
        >
          {this.state.editMode ? (
            this.props.select ? (
              <div style={{ marginBottom: '10px' }}>
                <div className="selectContainerBibliographies">
                  <div className="selectGrow">
                    <Select
                      style={{ width: '100%' }}
                      value={value.name}
                      options={this.props.items.map((option) => {
                        option.value = option.id;
                        option.label =
                          this.props.nameForValue === 'cycleSelected'
                            ? option.title
                            : option.shortname
                              ? option.shortname
                              : option.name;
                        return option;
                      })}
                      onChange={(selectedOption) => {
                        this.setState({
                          value: {
                            id: selectedOption.id,
                            name: selectedOption.label,
                          },
                        });
                      }}
                      ref="select"
                      openMenuOnFocus
                    />
                  </div>
                  <div className="check">
                    <i onClick={this.onSubmit} className="fa fa-check fa-lg" />
                  </div>
                  <div className="cross">
                    <i
                      onClick={() =>
                        this.setState({ editMode: false, error: false })
                      }
                      className="fa fa-close fa-lg"
                    />
                  </div>
                </div>
              </div>
            ) : this.props.textArea ? (
              <InlineTextArea
                defaultValue={this.props.value}
                onChange={(value) => {
                  {
                    value.trim()
                      ? this.setState({ value })
                      : this.setState({ value: '' });
                  }
                }}
                close={() => this.setState({ editMode: false, error: false })}
                onSubmit={this.onSubmit}
                type={this.props.isPassword ? 'password' : 'text'}
                error={this.state.error}
              />
            ) : (
              <div>
                {this.state.error && this.props.isPassword && (
                  <div className="error-message">
                    Password doesn't meet requirements
                  </div>
                )}
                <InlineInput
                  defaultValue={
                    this.props.value.type === 'a'
                      ? this.props.value.props.href.substring(
                          39,
                          this.props.value.props.href.length,
                        )
                      : this.props.value
                  }
                  onChange={(value) => {
                    if (this.props.isPassword) {
                      if (this.validatePassword(value)) {
                        this.setState({ value });
                      }
                    } else {
                      if (this.props.valueName === 'Email') {
                        if (this.validateEmail(value)) {
                          this.setState({ value });
                        }
                      } else if (
                        this.props.valueName === 'Last Name' ||
                        this.props.valueName === 'First Name'
                      ) {
                        {
                          value.trim()
                            ? this.setState({ value })
                            : this.setState({ value: '' });
                        }
                      } else {
                        this.setState({ value });
                      }
                    }
                  }}
                  close={() => this.setState({ editMode: false, error: false })}
                  onSubmit={this.onSubmit}
                  type={this.props.isPassword ? 'password' : 'text'}
                  error={this.state.error}
                />
              </div>
            )
          ) : (
            <div className="value" title={this.props.value}>
              {this.props.isPassword ? (
                '************'
              ) : this.props.select &&
                this.props.value &&
                this.props.value.name ? (
                this.props.value.name
              ) : this.props.valueName == 'User ID' ? (
                <a
                  href={`https://app.intercom.com/apps/${INTERCOM_APP_ID}/users/show?email=${
                    this.props.profile ? this.props.profile.email : ''
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.props.value}
                </a>
              ) : this.props.nameForValue === 'yourText' ? (
                <textarea
                  className="textArea address-user-verification"
                  value={this.props.value.replace(/;/g, '\n')}
                  disabled
                />
              ) : (
                this.props.value
              )}
            </div>
          )}
        </Col>
        {!this.state.editMode && !this.props.noEditable && (
          <Col
            style={{ paddingLeft: '41px', paddingRight: '0px' }}
            xs={2}
            md={2}
            lg={2}
          >
            {this.props.editable && (
              <div>
                <i
                  onClick={() => {
                    if (this.props.load) this.props.load();
                    this.setState({
                      editMode: true,
                      value: this.props.value,
                    });
                  }}
                  className="fa fa-pencil fa-lg valueName"
                />
                {this.props.nameForValue === 'stripeId' &&
                  this.props.iOStoStripe && (
                    <div>
                      <i
                        onClick={this.onSubmit}
                        className="fa fa-rotate-left fa-lg"
                        data-tooltip-id="iOStoStripe"
                      />

                      <ReactTooltip
                        id="iOStoStripe"
                        place="left"
                        variant="dark"
                        className="tooltipStyle"
                      >
                        Revert subscription back to iOS
                      </ReactTooltip>
                    </div>
                  )}
              </div>
            )}
          </Col>
        )}
        {this.state.error && this.props.isPassword && (
          <div className="error-message">
            <ul>
              <li>Minimum password length of at least eight (8) characters</li>
              <ul>
                Password complexity contains characters from at least three of
                the following four categories:
                <li>Uppercase alphabetic characters</li>
                <li>Lowercase alphabetic characters</li>
                <li>Numeric digits (0-9)</li>
                <li>
                  Non-alphanumeric special characters such as !, $, # or %.
                </li>
              </ul>
            </ul>
          </div>
        )}
      </Row>
    );
  }
}
