import * as type from './actionTypes';
import {
  BOOKS_MISSING_ANSWERS_REPORT_ERROR,
  BOOKS_MISSING_ANSWERS_REPORT_LOADING,
  BOOKS_MISSING_ANSWERS_REPORT_SUCCESS,
} from './components/BooksMissingAnswersReport/reportStates.js';

export const getBibliographyReportForGivenRates = (
  rates,
  selectedRatingExams,
  multiple,
) => ({
  type: type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES,
  rates,
  selectedRatingExams,
  multiple,
});

export const getBibliographyReportForGivenRatesSuccess = (
  bibliographyReportCSVJsonData,
) => ({
  type: type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES_SUCCESS,
  bibliographyReportCSVJsonData,
});

export const profileSheetCsvReport = (profileSheetCsv) => ({
  type: type.GET_PROFILE_SHEET_CSV,
  profileSheetCsv,
});

export const getBibliographyReportForGivenRatesError = (errorMessage) => ({
  type: type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES_ERROR,
  errorMessage,
});

export const getTopicsReportForGivenRates = (
  rates,
  selectedRatingExams,
  multiple,
) => ({
  type: type.GET_TOPICS_REPORT_FOR_RATES,
  rates,
  selectedRatingExams,
  multiple,
});

export const getTopicsReportForGivenRatesSuccess = (
  topicsReportCSVJsonData,
) => ({
  type: type.GET_TOPICS_REPORT_FOR_RATES_SUCCESS,
  topicsReportCSVJsonData,
});

export const getTopicsReportForGivenRatesError = (topicsReportCSVJsonData) => ({
  type: type.GET_TOPICS_REPORT_FOR_RATES_ERROR,
  topicsReportCSVJsonData,
});

export const getUserSubmittedQuestionsReportForGivenRates = (rates) => ({
  type: type.GET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES,
  rates,
});

export const callUsersCount = () => ({
  type: type.CALL_USERS_COUNT,
});

export const getUsersCount = (query) => ({
  type: type.GET_USERS_COUNT,
  query,
});

export const setGraphSagas = (graph) => ({
  type: type.SET_GRAPH,
  graph,
});

export const getUsersCountSuccess = (usersCountData) => ({
  type: type.GET_USERS_COUNT_SUCCESS,
  usersCountData,
});

export const getUsersCountError = (errorMessage) => ({
  type: type.GET_USERS_COUNT_ERROR,
  errorMessage,
});

export const getHistoricalUsersReportSagas = (
  lastCustomer,
  startingDate,
  endingDate,
  lessCustomers,
) => ({
  type: type.GET_HISTORICAL_USERS_REPORT,
  lastCustomer,
  startingDate,
  endingDate,
  lessCustomers,
});

export const setHistoricalUsersReport = (data) => ({
  type: type.SET_HISTORICAL_USERS_REPORT,
  data,
});

export const getHistoricalUsersReportError = () => ({
  type: type.GET_HISTORICAL_USERS_REPORT_ERROR,
});

export const setCompletionStatus = (data) => ({
  type: type.GET_COMPLETION_STATUS,
  data,
});

export const clearProps = () => ({
  type: type.CLEAR_PROPS,
});

export const clearAPIError = () => ({
  type: type.CLEAR_API_ERROR,
});

export const getActiveSubscriptions = (
  lastCustomerSubscription,
  askingFor,
) => ({
  type: type.GET_ACTIVE_SUBSCRIPTIONS,
  lastCustomerSubscription,
  askingFor,
});

export const setActiveSubscriptions = (
  data,
  hasMore,
  lastCustomerSubscription,
  askingFor,
) => ({
  type: type.SET_ACTIVE_SUBSCRIPTIONS,
  data,
  hasMore,
  lastCustomerSubscription,
  askingFor,
});

export const setIntercomActiveUsers = (
  data,
  hasMore,
  lastCustomerSubscription,
) => ({
  type: type.SET_INTERCOM_ACTIVE_USERS,
  data,
  hasMore,
  lastCustomerSubscription,
});

export const setIntercomActiveSubscriptionsError = () => ({
  type: type.SET_INTERCOM_ACTIVE_SUBSCRIPTIONS_ERROR,
});

export const setLastCustomerSubscription = (
  hasMore,
  lastCustomerSubscription,
) => ({
  type: type.SET_LAST_CUSTOMER_SUBSCRIPTION,
  lastCustomerSubscription,
  hasMore,
});

export const clearPropsSubscriptions = () => ({
  type: type.CLEAR_PROPS_SUBSCRIPTIONS,
});

export const setActiveSubscriptionsError = () => ({
  type: type.SET_ACTIVE_SUBSCRIPTIONS_ERROR,
});

export const setActiveSubscriptionsErrorFalse = () => ({
  type: type.SET_ACTIVE_SUBSCRIPTIONS_ERROR_FALSE,
});

export const getMasterUserReport = (lastCustomer) => ({
  type: type.GET_MASTER_USER_REPORT,
  lastCustomer,
});

export const getProfileSheetReport = (cycles) => ({
  type: type.GET_PROFILE_SHEET_REPORT,
  cycles,
});
export const downloadMasterUserReport = () => ({
  type: type.DOWNLOAD_MASTER_USER_REPORT,
});

export const downloadActiveSubscriptions = () => ({
  type: type.DOWNLOAD_ACTIVE_SUBSCRIPTIONS_REPORT,
});

export const setdownloadMasterUserReport = (response) => ({
  type: type.SET_DOWNLOAD_MASTER_USER_REPORT,
  response,
});

export const setdownloadActiveSubscriptions = (response) => ({
  type: type.SET_DOWNLOAD_ACTIVE_SUBSCRIPTIONS,
  response,
});

export const latestCsvCreationDate = () => ({
  type: type.LATEST_CSV_CREATION_DATE,
});

export const latestCsvCreationDateSubscriptions = () => ({
  type: type.LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE,
});

export const setlatestCsvCreationDate = (response) => ({
  type: type.SET_LATEST_CSV_CREATION_DATE,
  response,
});

export const setlatestCsvCreationDateSubscriptions = (response) => ({
  type: type.SET_LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE,
  response,
});

export const setlatestCsvCreationDateError = () => ({
  type: type.SET_LATEST_CSV_CREATION_DATE_ERROR,
});

export const clearAutomaticMasterReportProps = () => ({
  type: type.CLEAR_AUTOMATIC_MASTER_REPORT_PROPS,
});

export const clearAutomaticActiveSubscriptions = () => ({
  type: type.CLEAR_AUTOMATIC_ACTIVE_SUBSCRIPTIONS,
});

export const setdownloadMasterUserReportError = () => ({
  type: type.SET_DOWNLOAD_MASTER_USER_REPORT_ERROR,
});

export const setMasterUserReport = (response) => ({
  type: type.SET_MASTER_USER_REPORT,
  response,
});

export const setMasterUserReportError = () => ({
  type: type.SET_MASTER_USER_REPORT_ERROR,
});

export const clearMasterReportProps = () => ({
  type: type.CLEAR_MASTER_REPORT_PROPS,
});

export const clearMasterReportAPIError = () => ({
  type: type.CLEAR_MASTER_REPORT_API_ERROR,
});

export const setUserSubmittedQuestionsReport = (response) => ({
  type: type.SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES,
  response,
});

export const setIsUserSubmittedQuestionsReportLoading = (value) => ({
  type: type.SET_IS_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_LOADING,
  value,
});

export const setUserSubmittedQuestionsReportError = (error) => ({
  type: type.SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_ERROR,
  error,
});

export const getNumberOfQuestionsEnabledPerRatingExamReport = (lastRate) => ({
  type: type.GET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT,
  lastRate,
});

export const setNumberOfQuestionsEnabledPerRatingExamReport = (response) => ({
  type: type.SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT,
  response,
});

export const clearNumberOfQuestionsEnabledPerRatingExamReportProps = () => ({
  type: type.CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_PROPS,
});

export const setNumberOfQuestionsEnabledPerRatingExamReportError = () => ({
  type: type.SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_ERROR,
});

export const clearNumberOfQuestionsEnabledPerRatingExamAPIError = () => ({
  type: type.CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_API_ERROR,
});

export const getIntercomActiveUsersReport = (lastCustomerSubscription) => ({
  type: type.GET_INTERCOM_ACTIVE_USERS_REPORT,
  lastCustomerSubscription,
});

export const clearIntercomActiveUsersReportProps = () => ({
  type: type.CLEAR_INTERCOM_ACTIVE_USERS_REPORT_PROPS,
});

export const clearIntercomActiveUsersAPIError = () => ({
  type: type.CLEAR_INTERCOM_ACTIVE_USERS_API_ERROR,
});

/* Favorite questions report */
export const generateFavoriteQuestionsReport = (
  ratesIds,
  selectedRatingExams,
) => ({
  type: type.GENERATE_FAVORITE_QUESTIONS_REPORT_FOR_RATES,
  ratesIds,
  selectedRatingExams,
});
export const getLastFavoriteQuestionsReports = () => ({
  type: type.GET_LAST_FAVORITE_QUESTIONS_REPORTS,
});
export const setLastFavoriteQuestionsReport = (reports) => ({
  type: type.SET_LAST_FAVORITE_QUESTIONS_REPORTS,
  reports,
});
export const setIsLoadingFavoriteQuestionsReport = (ratesIds) => ({
  type: type.LOADING_FAVORITE_QUESTIONS_REPORT,
  ratesIds,
});

/* Rate questions report */
export const generateRateQuestionsReport = (ratesIds) => ({
  type: type.GENERATE_RATE_QUESTIONS_REPORT,
  ratesIds,
});
export const getLastRateQuestionsReports = () => ({
  type: type.GET_LAST_RATE_QUESTIONS_REPORTS,
});
export const setLastRateQuestionsReport = (reports) => ({
  type: type.SET_LAST_RATE_QUESTIONS_REPORTS,
  reports,
});
export const setIsLoadingRateQuestionsReport = (ratesIds) => ({
  type: type.LOADING_RATE_QUESTIONS_REPORT,
  ratesIds,
});

/* Usage report */
export const generateUsageReport = (
  startDate,
  endDate,
  startDateComparation,
  endDateComparation,
) => ({
  type: type.GENERATE_USAGE_REPORT,
  startDate,
  endDate,
  startDateComparation,
  endDateComparation,
});
export const setUsageReportIsLoading = (value) => ({
  type: type.SET_USAGE_REPORT_IS_LOADING,
  value,
});
export const setCreatingUsageReportInBackground = (value) => ({
  type: type.SET_CREATING_USAGE_REPORT_IN_BACKGROUND,
  value,
});
export const getUsageReports = () => ({
  type: type.GET_USAGE_REPORTS,
});
export const setUsageReports = (reports) => ({
  type: type.SET_USAGE_REPORTS,
  reports,
});
export const setUsageReportsIsLoading = (value) => ({
  type: type.SET_USAGE_REPORTS_IS_LOADING,
  value,
});

/* Books missing answers report */
export const getLatestBooksMissingAnswersReport = () => ({
  type: type.GET_LATEST_BOOKS_MISSING_ANSWERS_REPORT,
});
export const generateBooksMissingAnswersReport = () => ({
  type: type.GENERATE_BOOKS_MISSING_ANSWERS_REPORT,
});
export const setBooksMissingAnswersReportData = (
  booksMissingAnswersReport,
) => ({
  type: type.SET_BOOKS_MISSING_ANSWERS_REPORT_DATA,
  payload: booksMissingAnswersReport,
});
export const setBooksMissingAnswersReportStateSuccess = () => ({
  type: type.SET_BOOKS_MISSING_ANSWERS_REPORT_STATE,
  payload: {
    state: BOOKS_MISSING_ANSWERS_REPORT_SUCCESS,
  },
});
export const setBooksMissingAnswersReportStateError = () => ({
  type: type.SET_BOOKS_MISSING_ANSWERS_REPORT_STATE,
  payload: {
    state: BOOKS_MISSING_ANSWERS_REPORT_ERROR,
  },
});
export const setBooksMissingAnswersReportStateLoading = () => ({
  type: type.SET_BOOKS_MISSING_ANSWERS_REPORT_STATE,
  payload: {
    state: BOOKS_MISSING_ANSWERS_REPORT_LOADING,
  },
});
