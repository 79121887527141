import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import Spinner from '../../../commonComponents/Spinner/Spinner';

// Usage example: see BooksMissingAnswersReport.jsx
export default function ReportWithoutCriteria({
  name,
  link,
  currentState,
  stateLoading,
  stateError,
  stateSuccess,
  getLatestFunc,
  generateFunc,
}) {
  const dispatch = useDispatch();
  const downloadRef = useRef(null);
  const [doDownload, setDoDownload] = useState(false);

  useEffect(() => {
    dispatch(getLatestFunc());
  }, []);

  useEffect(() => {
    if (doDownload && currentState === stateSuccess && downloadRef.current) {
      downloadRef.current.click();
    }
  }, [currentState, doDownload, downloadRef]);

  const handleDownload = (e) => {
    if (!link || link.length === 0) {
      setDoDownload(true);
      // Prevent reloading the page if there aren't any previous reports
      e.preventDefault();
    }
  };

  const handleGenerate = () => {
    dispatch(generateFunc());
    setDoDownload(true);
  };

  if (currentState === stateLoading) {
    return <Spinner />;
  }
  return (
    <>
      <Row>
        <Col>
          <h3>{name}</h3>
        </Col>
      </Row>
      <Row>
        <Col className="d-block col-6">
          <a href={link} style={{ textDecoration: 'none' }}>
            <div
              className="whiteButtons"
              onClick={handleDownload}
              ref={downloadRef}
            >
              Download Latest
            </div>
          </a>
        </Col>
        <Col className="d-block col-6">
          <div className="whiteButtons" onClick={handleGenerate}>
            Generate
          </div>
        </Col>
      </Row>
      {currentState === stateError && (
        <Row>
          <Col>
            <div className="error">
              Error retrieving latest or generating report. Please try again.
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}
