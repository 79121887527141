import React, { useRef, useState } from 'react';

import { SimpleDatePicker } from '../../../../commonComponents/Datepicker/Datepicker';

export function NewUsageStatisticsReport({
  generateUsageReport,
  usageStatisticsReportIsLoading,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [disabledDatePicker, setDisabledDatePicker] = useState(true);
  const fromDatePickerRef = useRef();

  const [startDateComparation, setStartDateComparation] = useState(null);
  const [endDateComparation, setEndDateComparation] = useState(null);
  const fromDatePickerRefComparation = useRef();

  const getStartOfDay = (date) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  const getEndOfDay = (date) => {
    date.setHours(23, 59, 59, 999);
    return date.getTime();
  };

  const handleGenerateReport = () => {
    generateUsageReport(
      getStartOfDay(startDate),
      getEndOfDay(endDate),
      getStartOfDay(startDateComparation),
      getEndOfDay(endDateComparation),
    );
  };

  // Defaults dates
  const setPresetDates = (preset) => {
    const today = new Date();
    let start;
    let end;

    switch (preset) {
      case 'today':
        start = today;
        end = today;
        setDisabledDatePicker(true);
        break;
      case 'week':
        start = new Date(today);
        start.setDate(today.getDate() - 7);
        end = today;
        setDisabledDatePicker(true);
        break;
      case 'month':
        start = new Date(today);
        start.setMonth(today.getMonth() - 1);
        end = today;
        setDisabledDatePicker(true);
        break;
      case 'year':
        start = new Date(today);
        start.setFullYear(today.getFullYear() - 1);
        end = today;
        setDisabledDatePicker(true);
        break;
      default:
        setDisabledDatePicker(false, () => {
          fromDatePickerRef.current.click();
          fromDatePickerRef.current.click();
        });
    }

    if (start && end) {
      setStartDate(start);
      setEndDate(end);
      setPresetDatesComparation(preset, start, end);
    }
    setSelectedPreset(preset);
  };

  const setPresetDatesComparation = (preset, start, end) => {
    const startComparation = new Date(start);
    const endComparation = new Date(end);

    switch (preset) {
      case 'year':
        startComparation.setFullYear(startComparation.getFullYear() - 1);
        endComparation.setFullYear(endComparation.getFullYear() - 1);
        break;
      case 'month':
        startComparation.setMonth(startComparation.getMonth() - 1);
        endComparation.setMonth(endComparation.getMonth() - 1);
        break;
      case 'week':
        startComparation.setDate(startComparation.getDate() - 7);
        endComparation.setDate(endComparation.getDate() - 7);
        break;
      case 'today':
        startComparation.setDate(startComparation.getDate() - 1);
        endComparation.setDate(endComparation.getDate() - 1);
        break;
      default:
        break;
    }

    if (start && end) {
      setStartDateComparation(startComparation);
      setEndDateComparation(endComparation);
    }
  };

  const isDisabledButton =
    !startDate ||
    !endDate ||
    !startDateComparation ||
    !endDateComparation ||
    usageStatisticsReportIsLoading;

  return (
    <>
      <div className="preset-options">
        <button
          onClick={() => setPresetDates('today')}
          className={selectedPreset === 'today' ? 'selected' : ''}
        >
          Today
        </button>
        <button
          onClick={() => setPresetDates('week')}
          className={selectedPreset === 'week' ? 'selected' : ''}
        >
          Last Week
        </button>
        <button
          onClick={() => setPresetDates('month')}
          className={selectedPreset === 'month' ? 'selected' : ''}
        >
          Last Month
        </button>
        <button
          onClick={() => setPresetDates('year')}
          className={selectedPreset === 'year' ? 'selected' : ''}
        >
          Last Year
        </button>
        <button
          onClick={() => setPresetDates('custom')}
          className={selectedPreset === 'custom' ? 'selected' : ''}
        >
          Custom
        </button>
      </div>

      <div className="datePickers">
        <div>
          <label ref={fromDatePickerRef}>
            From date:
            <SimpleDatePicker
              date={startDate}
              onChange={(date) => setStartDate(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
        <div>
          <label>
            Until date:
            <SimpleDatePicker
              date={endDate}
              onChange={(date) => setEndDate(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
      </div>
      <div className="datePickers">
        <div>
          <label ref={fromDatePickerRefComparation}>
            From date comparation:
            <SimpleDatePicker
              date={startDateComparation}
              onChange={(date) => setStartDateComparation(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
        <div>
          <label>
            Until date comparation:
            <SimpleDatePicker
              date={endDateComparation}
              onChange={(date) => setEndDateComparation(date)}
              className="datePicker"
              placeholderText="MM/DD/YYYY"
              disabled={disabledDatePicker}
            />
          </label>
        </div>
      </div>
      <div
        className={
          isDisabledButton
            ? 'generate-report generate-report-disabled'
            : 'generate-report'
        }
        onClick={handleGenerateReport}
      >
        Generate Report
      </div>
    </>
  );
}
