import React, { useEffect, useMemo, useState } from 'react';

import { SimpleDatePicker } from '../../../../commonComponents/Datepicker/Datepicker';
import Spinner from '../../../../commonComponents/Spinner/Spinner';

export function HistoricalReportTable({
  reports,
  usageStatisticsReportsIsLoading,
  usageStatisticsReportIsLoading,
  creatingUsageReportInBackground = false,
  setCreatingUsageReportInBackground,
}) {
  const [filterDate, setFilterDate] = useState(null);
  const [latestReport, setLatestReport] = useState(null);

  useEffect(() => {
    if (reports?.length > 0) {
      const lastReport = reports.sort((r1, r2) => r2.id - r1.id)[0];
      if (lastReport.isLoading) {
        setCreatingUsageReportInBackground(true);
        setLatestReport(null);
      } else {
        const dateCreatedAt = new Date(lastReport.createdAt);
        setLatestReport({
          ...lastReport,
          createdAtParsed: `${dateCreatedAt.getMonth() + 1}/${dateCreatedAt.getDate()}/${dateCreatedAt.getFullYear()} ${dateCreatedAt.getHours()}:${dateCreatedAt.getMinutes().toString().padStart(2, '0')}`,
        });
        setCreatingUsageReportInBackground(false);
      }
    }
  }, [reports]);

  const parseDate = (dateString) => {
    // object date to 'dd/mm/yyyy'
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day);
  };
  const isSameDate = (date1, date2) =>
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();

  // filter and sort dates
  const filteredReports = useMemo(() => {
    if (!filterDate) return reports.sort((r1, r2) => r2.id - r1.id);

    return reports
      .filter((report) => {
        const parseStartDate = parseDate(report.startDate);
        const parseEndDate = parseDate(report.endDate);
        const parseStartComparationDate = parseDate(
          report.startDateComparation,
        );
        const parseEndComparationDate = parseDate(report.endDateComparation);
        return (
          isSameDate(parseStartDate, filterDate) ||
          isSameDate(parseEndDate, filterDate) ||
          isSameDate(parseStartComparationDate, filterDate) ||
          isSameDate(parseEndComparationDate, filterDate)
        );
      })
      .sort((r1, r2) => r2.id - r1.id);
  }, [reports, filterDate]);

  // this is a loading for generation http request, not for the report generation itself
  const loading =
    (!usageStatisticsReportIsLoading && usageStatisticsReportsIsLoading) ||
    (usageStatisticsReportIsLoading && !usageStatisticsReportsIsLoading);

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <div className="filterDate">
        {creatingUsageReportInBackground && (
          <div className="background-loading">
            <Spinner />
            <p>
              There is a report in process. It might take some time, please
              check back in a few moments.
            </p>
          </div>
        )}

        {!creatingUsageReportInBackground && latestReport && (
          <div className="latest-created">
            <a href={latestReport.url} target="_blank" rel="noreferrer">
              Download the latest report ( {latestReport.createdAtParsed} )
            </a>
          </div>
        )}

        <label>
          Filter:
          <SimpleDatePicker
            date={filterDate}
            onChange={(date) => setFilterDate(date)}
            className="datePicker"
            placeholderText="MM/DD/YYYY"
          />
        </label>
        <button
          className="clearFilterBy"
          onClick={() => {
            setFilterDate(null);
          }}
        >
          <i className="fa fa-close fa-lg" />
        </button>
      </div>

      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>From Date</th>
              <th>Until Date</th>
              <th>From Date Comparison</th>
              <th>Until Date Comparison</th>
              <th>Link Report</th>
            </tr>
          </thead>
          <tbody>
            {filteredReports.map((r) => (
              <tr key={r.id}>
                <td>{r.id}</td>
                <td>{r.startDate}</td>
                <td>{r.endDate}</td>
                <td>{r.startDateComparation}</td>
                <td>{r.endDateComparation}</td>
                <td>
                  <a href={r.url} target="_blank" rel="noreferrer">
                    View Report
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
