import { Map } from 'immutable';

import * as type from './actionTypes';
import {
  BOOKS_MISSING_ANSWERS_REPORT_LOADING,
  BOOKS_MISSING_ANSWERS_REPORT_SUCCESS,
} from './components/BooksMissingAnswersReport/reportStates';

const initialState = Map({
  bibliographyCSVJsonReport: [],
  topicsCSVJsonReport: [],
  userSubmittedQuestionsReportCSVJson: [],
  isUserSubmittedQuestionsReportLoading: false,
  userSubmittedQuestionsReportError: '',
  profileSheetCsv: [],
  isLoading: false,
  isLoading_topics: false,
  errorMessage: '',
  errorMessage_topics: '',
  usersCount: [],
  setGraph: { labels: '', datasets: [] },
  values: [],
  finish: false,
  lastCustomer: '',
  count: 0,
  historicalReportError: false,
  allRates: [],
  CSVData: [],
  lastCustomerSubscription: '',
  hasMoreSubscription: true,
  completionStatus: [],
  askingFor: 'active',
  activeSubscriptionError: false,
  lastCustomerMaster: '',
  masterReportData: [
    [
      'Stripe ID',
      'Transaction iOS ID',
      'Email',
      'First Name',
      'Last Name',
      'Created At',
      'Rank',
      'Duty State',
      'Rate',
      'Cycle',
      'Username',
      'Timezone',
      'Image URL',
      'Profile Privacy',
      'Highest Education Level',
      'Current Enrollment Status',
      'Name of Current Educational Institution',
      'Estimated Degree Completion Date',
      'Enrollment date modified',
      'Country code',
      'Phone Number',
      'Date Disclaimer Accepted',
      'Practice Exam Points',
      'Practice Exam Average',
      'Practice Exam Taken',
      'Daily Quiz Points',
      'Daily Quiz Average',
      'Daily Quiz Total',
      'Total Points',
      'Correct Answers',
      'Incorrect Answers',
      'Total Answers',
      'Friends Invited',
      'Friend Request Received',
      'Number of Disabled Questions',
      'PSP Taken',
      'Last Login',
      'Last Subscription Canceled',
      'Last Rank Changed',
      'Last Rank Changed by User',
      'Subscription Set To Cancel',
      'Subscription Revert Cancellation',
      'User Cancellation Reason',
      'User Cancellation Reason from intercom',
      'User Set To Cancel Date',
      'Customer Notes',
      'Do Not Contact',
      'Intercom Unsubscribe',
      'User Verification Text',
      'User Verification Email',
      'User Verification Last Name',
      'User Verification First Name',
      'User Verification T-Shirt Size',
      'User Verification Last Update',
      'Coupons',
      'Billing Country',
      'Billing Address Line 1',
      'Billing Address Line 2',
      'Billing Address Postal Code',
      'Billing Address State',
      'Subscription status',
      'Stripe Plan Name',
      'Subscription source',
    ],
  ],
  masterReportError: false,
  masterReportHasMore: true,
  csvDataAvailably: false,
  automaticMasterReportData: '',
  automaticSubscriptionsReport: '',
  latestCsvCreationDate: 0,
  latestCsvCreationDateSubscriptions: 0,
  csvDataSubsActive: false,
  numberOfQuestionsPerRatingExamReportData: [],
  numberOfQuestionsPerRatingExamReportHasMore: true,
  nextRateToUse: '',
  numberOfQuestionsPerRatingExamReportOverallUnique: 0,
  numberOfQuestionsPerRatingExamReportApiError: false,
  intercomActiveUsersData: [],
  lastCustomerSubscriptionIntercom: '',
  intercomActiveUsersHasMore: true,
  intercomActiveUsersApiError: false,

  /* Favorite Questions Report */
  loadingFavoriteQuestionsReports: false,
  lastFavoriteQuestionsReports: null,
  /* Rate Questions Report */
  lastRateQuestionsReports: null,
  /* Usage Statistics Report */
  usageStatisticsReports: [],
  usageStatisticsReportsIsLoading: false,
  usageStatisticsReportIsLoading: false,

  /* Books missing answers report */
  booksMissingAnswersReportData: {
    link: '',
    state: BOOKS_MISSING_ANSWERS_REPORT_LOADING,
  },
  booksMissingAnswersReportState: BOOKS_MISSING_ANSWERS_REPORT_LOADING,
});

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_TOPICS_REPORT_FOR_RATES:
      return state
        .set('isLoading_topics', true)
        .set('errorMessage_topics', '')
        .set('topicsCSVJsonReport', []);
    case type.GET_TOPICS_REPORT_FOR_RATES_SUCCESS:
      const topicsReportData = [
        [
          `Rate`,
          `Topic`,
          `Sub Topic`,
          `# of Chapters per sub-topic`,
          `# of Questions per sub-topic`,
        ],
      ];
      return state
        .set('isLoading_topics', false)
        .set(
          'topicsCSVJsonReport',
          topicsReportData.concat(action.topicsReportCSVJsonData),
        );
    case type.GET_TOPICS_REPORT_FOR_RATES_ERROR:
      return state
        .set('isLoading_topics', false)
        .set('errorMessage_topics', action.errorMessage);
    case type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES:
      return state
        .set('isLoading', true)
        .set('errorMessage', '')
        .set('bibliographyCSVJsonReport', []);
    case type.GET_PROFILE_SHEET_REPORT:
      return state.set('isLoading', true);
    case type.SET_GRAPH:
      return state.set('setGraph', action.graph);
    case type.CALL_USERS_COUNT:
      return state.set('isLoading', true);
    case type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES_SUCCESS:
      return state
        .set('isLoading', false)
        .set('bibliographyCSVJsonReport', action.bibliographyReportCSVJsonData);
    case type.GET_PROFILE_SHEET_CSV:
      return state
        .set('isLoading', false)
        .set('profileSheetCsv', action.profileSheetCsv);
    case type.GET_BIBLIOGRAPHY_REPORT_FOR_RATES_ERROR:
      return state
        .set('isLoading', false)
        .set('errorMessage', action.errorMessage);
    case type.GET_USERS_COUNT:
      return state
        .set('isLoding', true)
        .set('errorMessage', '')
        .set('usersCount', []);
    case type.GET_USERS_COUNT_SUCCESS:
      return state
        .set('isLoading', false)
        .set('usersCount', action.usersCountData);
    case type.GET_USERS_COUNT_ERROR:
      return state
        .set('isLoading', false)
        .set('usersCount', [])
        .set('errorMessage', action.errorMessage);
    case type.SET_HISTORICAL_USERS_REPORT:
      const valuesState = state.get('values');
      const valuesConcat = valuesState.concat(action.data.values);
      let allRates = state.get('allRates');
      if (action.data.allRates.length > 0) {
        allRates = action.data.allRates;
      }
      return state
        .set('values', valuesConcat)
        .set('finish', action.data.finish)
        .set('lastCustomer', action.data.lastCustomer)
        .set('allRates', allRates);
    case type.GET_HISTORICAL_USERS_REPORT_ERROR:
      return state.set('historicalReportError', true);
    case type.GET_COMPLETION_STATUS:
      return state.set('completionStatus', action.data);
    case type.CLEAR_API_ERROR:
      return state.set('historicalReportError', false);
    case type.CLEAR_PROPS:
      return state
        .set('values', [])
        .set('finish', false)
        .set('lastCustomer', '')
        .set('count', 0)
        .set('historicalReportError', false)
        .set('allRates', []);
    case type.SET_ACTIVE_SUBSCRIPTIONS:
      let csvData = state.get('CSVData');
      if (csvData.length > 0) {
        csvData.forEach((each, index) => {
          csvData[index][1] += action.data[index][1];
        });
      } else {
        csvData = action.data;
      }
      return state
        .set('CSVData', csvData)
        .set('lastCustomerSubscription', action.lastCustomerSubscription)
        .set('hasMoreSubscription', action.hasMore)
        .set('askingFor', action.askingFor);
    case type.CLEAR_PROPS_SUBSCRIPTIONS:
      return state
        .set('CSVData', [])
        .set('lastCustomerSubscription', '')
        .set('hasMoreSubscription', true)
        .set('askingFor', 'active');
    case type.SET_ACTIVE_SUBSCRIPTIONS_ERROR:
      return state.set('activeSubscriptionError', true);
    case type.SET_ACTIVE_SUBSCRIPTIONS_ERROR_FALSE:
      return state.set('activeSubscriptionError', false);
    case type.SET_INTERCOM_ACTIVE_USERS:
      const intercomActiveUsersCurrentData = state.get(
        'intercomActiveUsersData',
      );
      let intercomActiveUsersConcatData = intercomActiveUsersCurrentData.concat(
        action.data,
      );
      if (!action.hasMore) {
        const intercomActiveUsersFinalCSV = [
          [
            'User Stripe ID',
            'User Email',
            'Last Device Platform',
            'Last Device Model',
            'Last Device Version',
          ],
        ];
        intercomActiveUsersConcatData = intercomActiveUsersFinalCSV.concat(
          intercomActiveUsersConcatData,
        );
      }
      return state
        .set('intercomActiveUsersData', intercomActiveUsersConcatData)
        .set(
          'lastCustomerSubscriptionIntercom',
          action.lastCustomerSubscription,
        )
        .set('intercomActiveUsersHasMore', action.hasMore);
    case type.CLEAR_INTERCOM_ACTIVE_USERS_REPORT_PROPS:
      return state
        .set('intercomActiveUsersData', [])
        .set('lastCustomerSubscriptionIntercom', '')
        .set('intercomActiveUsersHasMore', true)
        .set('intercomActiveUsersApiError', false);
    case type.SET_INTERCOM_ACTIVE_SUBSCRIPTIONS_ERROR:
      return state.set('intercomActiveUsersApiError', true);
    case type.CLEAR_INTERCOM_ACTIVE_USERS_API_ERROR:
      return state.set('intercomActiveUsersApiError', false);
    case type.SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT:
      const previousOverallUnique = state.get(
        'numberOfQuestionsPerRatingExamReportOverallUnique',
      );
      const newOverallUnique =
        previousOverallUnique + action.response.overallUnique;
      const numberOfQuestionsCurrentData = state.get(
        'numberOfQuestionsPerRatingExamReportData',
      );
      let numberOfQuestionsConcatData = numberOfQuestionsCurrentData.concat(
        action.response.data,
      );
      if (!action.response.hasMore) {
        const numberOfQuestionsFinalCSV = [
          ['OVERALL UNIQUE', newOverallUnique],
        ];
        numberOfQuestionsConcatData = numberOfQuestionsFinalCSV.concat(
          numberOfQuestionsConcatData,
        );
      }
      return state
        .set(
          'numberOfQuestionsPerRatingExamReportData',
          numberOfQuestionsConcatData,
        )
        .set(
          'numberOfQuestionsPerRatingExamReportHasMore',
          action.response.hasMore,
        )
        .set('nextRateToUse', action.response.nextRateToUse)
        .set(
          'numberOfQuestionsPerRatingExamReportOverallUnique',
          newOverallUnique,
        );

    case type.CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_PROPS:
      return state
        .set('numberOfQuestionsPerRatingExamReportData', [])
        .set('numberOfQuestionsPerRatingExamReportHasMore', true)
        .set('nextRateToUse', '')
        .set('numberOfQuestionsPerRatingExamReportOverallUnique', 0)
        .set('numberOfQuestionsPerRatingExamReportApiError', false);

    case type.SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_ERROR:
      return state.set('numberOfQuestionsPerRatingExamReportApiError', true);

    case type.CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_API_ERROR:
      return state.set('numberOfQuestionsPerRatingExamReportApiError', false);
    case type.SET_MASTER_USER_REPORT:
      const masterReportDataCurrent = state.get('masterReportData');
      const masterReportDataConcat = masterReportDataCurrent.concat(
        action.response.result,
      );
      return state
        .set('masterReportData', masterReportDataConcat)
        .set('lastCustomerMaster', action.response.lastCustomer)
        .set('masterReportHasMore', action.response.hasMore);
    case type.CLEAR_MASTER_REPORT_PROPS:
      return state
        .set('masterReportData', [
          [
            'Stripe ID',
            'Transaction iOS ID',
            'Email',
            'First Name',
            'Last Name',
            'Created At',
            'Rank',
            'Duty State',
            'Rate',
            'Cycle',
            'Username',
            'Timezone',
            'Image URL',
            'Profile Privacy',
            'Highest Education Level',
            'Current Enrollment Status',
            'Name of Current Educational Institution',
            'Estimated Degree Completion Date',
            'Enrollment date modified',
            'Country code',
            'Phone Number',
            'Date Disclaimer Accepted',
            'Practice Exam Points',
            'Practice Exam Average',
            'Practice Exam Taken',
            'Daily Quiz Points',
            'Daily Quiz Average',
            'Daily Quiz Total',
            'Total Points',
            'Correct Answers',
            'Incorrect Answers',
            'Total Answers',
            'Friends Invited',
            'Friend Request Received',
            'Number of Disabled Questions',
            'PSP Taken',
            'Last Login',
            'Last Subscription Canceled',
            'Last Rank Changed',
            'Last Rank Changed by User',
            'Subscription Set To Cancel',
            'Subscription Revert Cancellation',
            'User Cancellation Reason',
            'User Cancellation Reason from intercom',
            'User Set To Cancel Date',
            'Customer Notes',
            'Do Not Contact',
            'Intercom Unsubscribe',
            'User Verification Text',
            'User Verification Email',
            'User Verification Last Name',
            'User Verification First Name',
            'User Verification T-Shirt Size',
            'User Verification Last Update',
            'Coupons',
            'Billing Country',
            'Billing Address Line 1',
            'Billing Address Line 2',
            'Billing Address Postal Code',
            'Billing Address State',
            'Subscription status',
            'Stripe Plan Name',
            'Subscription source',
          ],
        ])
        .set('lastCustomerMaster', '')
        .set('masterReportHasMore', true)
        .set('masterReportError', false);
    case type.CLEAR_MASTER_REPORT_API_ERROR:
      return state.set('masterReportError', false);
    case type.SET_MASTER_USER_REPORT_ERROR:
      return state.set('masterReportError', true);
    case type.SET_DOWNLOAD_MASTER_USER_REPORT:
      const automaticmasterReportUrl = action.response.result;
      return state
        .set('csvDataAvailably', true)
        .set('automaticMasterReportData', automaticmasterReportUrl);
    case type.SET_DOWNLOAD_ACTIVE_SUBSCRIPTIONS:
      return state
        .set('csvDataSubsActive', true)
        .set('automaticSubscriptionsReport', action.response.result);
    case type.CLEAR_AUTOMATIC_MASTER_REPORT_PROPS:
      return state
        .set('automaticMasterReportData', '')
        .set('csvDataAvailably', false);
    case type.SET_LATEST_CSV_CREATION_DATE:
      return state.set('latestCsvCreationDate', action.response);
    case type.SET_LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE:
      return state.set('latestCsvCreationDateSubscriptions', action.response);
    case type.CLEAR_AUTOMATIC_ACTIVE_SUBSCRIPTIONS:
      return state
        .set('automaticSubscriptionsReport', '')
        .set('csvDataSubsActive', false);
    case type.SET_IS_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_LOADING:
      return state.set('isUserSubmittedQuestionsReportLoading', action.value);
    case type.SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES:
      return state.set('userSubmittedQuestionsReportCSVJson', action.response);
    case type.SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_ERROR:
      return state.set('userSubmittedQuestionsReportError', action.error);

    /* Favorite questions report */
    case type.SET_LAST_FAVORITE_QUESTIONS_REPORTS:
      return state.set('lastFavoriteQuestionsReports', action.reports);
    case type.LOADING_FAVORITE_QUESTIONS_REPORT: {
      const existingReports = state.get('lastFavoriteQuestionsReports');
      // create new reports if they don't exist (this works only the first time for each rate)
      const updatedReports = action.ratesIds.reduce(
        (reports, rateId) => {
          const existingReport = reports.find(
            (report) => report.rate === rateId,
          );
          if (existingReport) {
            existingReport.isLoading = true;
          } else {
            reports.push({ rate: rateId, isLoading: true });
          }
          return reports;
        },
        [...existingReports],
      );

      return state.set('lastFavoriteQuestionsReports', updatedReports);
    }

    /* Rate questions report */
    case type.SET_LAST_RATE_QUESTIONS_REPORTS:
      return state.set('lastRateQuestionsReports', action.reports);
    case type.LOADING_RATE_QUESTIONS_REPORT: {
      const existingReports = state.get('lastRateQuestionsReports');
      // create new reports if they don't exist (this works only the first time for each rate)
      const updatedReports = action.ratesIds.reduce(
        (reports, rateId) => {
          const existingReport = reports.find(
            (report) => report.rate === rateId,
          );
          if (existingReport) {
            existingReport.isLoading = true;
          } else {
            reports.push({ rate: rateId, isLoading: true });
          }
          return reports;
        },
        [...existingReports],
      );

      return state.set('lastRateQuestionsReports', updatedReports);
    }
    /* Usage statistics report */
    case type.SET_USAGE_REPORTS_IS_LOADING:
      return state.set('usageStatisticsReportsIsLoading', action.value);
    case type.SET_USAGE_REPORTS:
      return state.set('usageStatisticsReports', action.reports);
    case type.SET_USAGE_REPORT_IS_LOADING:
      return state.set('usageStatisticsReportIsLoading', action.value);
    case type.SET_CREATING_USAGE_REPORT_IN_BACKGROUND:
      return state.set('creatingUsageReportInBackground', action.value);

    /* Books missing answers report */
    case type.GET_LATEST_BOOKS_MISSING_ANSWERS_REPORT:
    case type.GENERATE_BOOKS_MISSING_ANSWERS_REPORT:
      return state;
    case type.SET_BOOKS_MISSING_ANSWERS_REPORT_DATA:
      if (action.payload.state === BOOKS_MISSING_ANSWERS_REPORT_SUCCESS) {
        return state.set('booksMissingAnswersReportData', {
          state: action.payload.state,
          link: action.payload.link,
        });
      }
      return state;
    case type.SET_BOOKS_MISSING_ANSWERS_REPORT_STATE:
      return state.set('booksMissingAnswersReportState', action.payload.state);

    default:
      return state;
  }
};

export default reportReducer;
